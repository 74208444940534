/* Implement custom javascript here */
jQuery(document).ready(function(){

    jQuery('.page-galeria-hanganyagok .gallery-title-area').matchHeight();

    setTimeout(function(){
        jQuery('body').addClass('loaded');
    }, 3000);

    // TagCloud
    jQuery("#tagCloud a").tagcloud({
        size: {start: 12, end: 20, unit: "px"},
        color: {start: '#595858', end: '#595858'}
    });

    checkUrls();
    
    jQuery(".user-picture img").click(function(){
        jQuery(".author-tooltip").toggleClass('hide');
    });
             
    jQuery(".osztondijas_image img").click(function(){
        var obj = jQuery(this).closest('li');

        obj.siblings().attr('class', 'inactive').end().toggleClass('inactive osztondijas_expanded');
       
    });   
});

function checkUrls(){
    var url = window.location.href; 
    
    if (url.indexOf("/szorvanyrol/") >= 0) {
        jQuery('#main-menu li a').each(function(){
            if(jQuery(this).attr('href').indexOf("szorvanyrol") >= 0){ jQuery(this).addClass('active'); }
        });

    }
    if (url.indexOf("/sajto") >= 0) {
        jQuery('#main-menu li a').each(function(){
            if(jQuery(this).attr('href').indexOf("sajto/rolunk-irtak") >= 0){ jQuery(this).addClass('active'); }
        });

    }
    if (url.indexOf("/partnereink/") >= 0) {
        //alert($('a[href$="ABC"]:first').attr('href'));
        jQuery('#main-menu li a').each(function(){
            if(jQuery(this).attr('href').indexOf("partnereink") >= 0){ jQuery(this).addClass('active'); }            
        });
    }
    if (url.indexOf("/osztondijasaink/") >= 0) {
        //alert($('a[href$="ABC"]:first').attr('href'));
        jQuery('#main-menu li a').each(function(){
            if(jQuery(this).attr('href').indexOf("osztondijasaink") >= 0){ jQuery(this).addClass('active'); }            
        });
    }
    if (url.indexOf("/hirek/") >= 0) {
        //alert($('a[href$="ABC"]:first').attr('href'));
        jQuery('#main-menu li a').each(function(){
            if(jQuery(this).attr('href').indexOf("hirek") >= 0){ jQuery(this).addClass('active'); }            
        });
    }
    if (url.indexOf("/galeria/") >= 0) {
        //alert($('a[href$="ABC"]:first').attr('href'));
        jQuery('#main-menu li a').each(function(){
            if(jQuery(this).attr('href').indexOf("galeria") >= 0){ jQuery(this).addClass('active'); }
        });
    }
    if (url.indexOf("/tudastar/") >= 0) {
        //alert($('a[href$="ABC"]:first').attr('href'));
        jQuery('#main-menu li a').each(function(){
            if(jQuery(this).attr('href').indexOf("tudastar") >= 0){ jQuery(this).addClass('active'); }
        });
    }
    if (url.indexOf("/munkankrol/") >= 0) {
        //alert($('a[href$="ABC"]:first').attr('href'));
        jQuery('#main-menu li a').each(function(){
            if(jQuery(this).attr('href').indexOf("munkankrol") >= 0){ jQuery(this).addClass('active'); }
        });
    }
    
    
    return true;
}

(function ($, Drupal) {

    Drupal.behaviors.partybus = {
        attach: function(context, settings) {

            
            $(window).on("load", function() {
                collage();
                $('a.gallery-item').colorbox({rel:'gal'});
            });

            function collage() {
                $('.Collage').removeWhitespace().collagePlus({
                    'fadeSpeed'     : 2000,
                    'targetHeight'  : 150,
                    'effect' : 'effect-1',
                    'allowPartialLastRow' : true
                });
            }


            var resizeTimer = null;
            $(window).bind('resize', function() {
                // hide all the images until we resize them
                // set the element you are scaling i.e. the first child nodes of ```.Collage``` to opacity 0
                $('.Collage .Image_Wrapper').css("opacity", 0);
                // set a timer to re-apply the plugin
                if (resizeTimer) clearTimeout(resizeTimer);
                resizeTimer = setTimeout(collage, 200);
            });

        }
    };

})(jQuery, Drupal);